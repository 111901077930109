<!-- <template>
  <div class="px-5">
    <button class="btn btn-link">Download</button>
    <a class="btn btn-link" href="" download>Download</a>
  </div>
</template> -->

<!-- <script>
import { onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "DownloadReport",
  setup() {
    const store = useStore();
    onMounted(() => {
      store.commit("setCurrentStage", null);
    });
  },
};
</script> -->

<!-- <style scoped></style> -->

<template>
  <div class="px-5">
    <a class="btn btn-link" :href="fileUrl" download>Download</a>
    <button class="btn btn-link" @click="downloadFile">Download</button>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "DownloadReport",
  setup() {
    const store = useStore();

    const fileUrl = "/emis_asc.xlsx";

    const downloadFile = () => {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "emis_asc.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    onMounted(() => {
      store.commit("setCurrentStage", null);
    });

    return {
      fileUrl,
      downloadFile,
    };
  },
};
</script>

<style scoped></style>
